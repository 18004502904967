import { signInWithPopup } from "firebase/auth";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { auth, googleAuthProvider } from "../firebase/config";

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoogleSignIn = async () => {
    try {
      const res = await signInWithPopup(auth, googleAuthProvider);
      localStorage.setItem("token", res.user.accessToken);
      localStorage.setItem("user", JSON.stringify(res.user));
      navigate("/admin");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <footer className="bg-customRed text-white text-center p-4">
      © MTL Dynamique 2024 |{" "}
      <Link to="/club-policy" className="hover:underline">
        {t("common.footer.privacy")}
      </Link>{" "}
      |{" "}
      <Link to="/code-of-conduct" className="hover:underline">
        {t("common.footer.terms")}
      </Link>{" "}
      |{" "}
      <Link onClick={handleGoogleSignIn} to="#" className="hover:underline">
        Admin
      </Link>
    </footer>
  );
};

export default Footer;
