import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const RegistrationPageNew = () => {
  useEffect(() => {
    // Automatically open the external link in a new tab
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLScglVHLKXl4IMiwvaOAYWsLBJ6YE8OGAc_6mOmWUvHqw1XplA/viewform",
      "_blank"
    );
  }, []); // Empty dependency array ensures this runs only once on mount
const { t } = useTranslation();

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="text-center p-6 rounded-lg shadow-md">
        <p className="text-lg mb-4">{t('registration.redirect.success')}</p>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLScglVHLKXl4IMiwvaOAYWsLBJ6YE8OGAc_6mOmWUvHqw1XplA/viewform"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-800 underline"
        >
          {t('registration.redirect.error')}
        </a>
      </div>
    </div>
  );
};

export default RegistrationPageNew;