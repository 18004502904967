import React from "react";
import CodeOfConduct from "../components/CodeOfConduct";


const CodeOfConductPage = () => {
  return (
      <div className="w-full flex flex-col items-center">

          <CodeOfConduct />
        
      </div>
    );
};

export default CodeOfConductPage;
