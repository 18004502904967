import React from "react";
import { useTranslation } from "react-i18next";
import contact from "../assets/contact.png";
import { FiPhone, FiMail } from "react-icons/fi";
import { FaMapMarkerAlt } from "react-icons/fa"; // Location icon
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css"; // Import Leaflet CSS

// Fix for default marker icons in Leaflet
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

const defaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41], // Size of the icon
  iconAnchor: [12, 41], // Point of the icon which will correspond to the marker's location
});

const ContactPage = () => {
  const { t } = useTranslation();

  // Locations to display on the map
  const locations = [
    {
      lat: 45.507751,
      lng: -73.77959,
      title: "West Island",
      directionsUrl:
        "https://www.google.com/maps/dir/?api=1&destination=45.507751,-73.77959",
      address: "4901 Rue du Collège – Beaubois, Pierrefonds, QC H8Y 3T4",
    },
    {
      lat: 45.506469,
      lng: -73.703116,
      title: "Montreal",
      directionsUrl:
        "https://www.google.com/maps/dir/?api=1&destination=45.506469,-73.703116",
      address: "2395 Boulevard Thimens, Saint-Laurent, QC H4R 1T4",
    },
    {
      lat: 45.3999449,
      lng: -73.5475581,
      title: "Southshore",
      directionsUrl:
        "https://www.google.com/maps/dir/?api=1&destination=45.3999449,-73.5475581",
      address: "125 Pl. Charles-Lemoyne, Sainte-Catherine, QC J5C 0A1",
    },
  ];

  return (
    <div className="flex flex-col items-center">
      <div>
        <img src={contact} alt="contactImage" className="w-full mb-10" />
      </div>

      <div className="w-full max-w-screen-lg flex flex-col items-center mt-10">
        <h3 className="text-4xl text-customRed text-center font-semibold">
          {t("common.contact.title")}
        </h3>

        <div className="w-4/5 flex flex-col sm:flex-row gap-5 mt-10">
          <div className="w-full flex flex-col items-center gap-5 my-5 sm:my-10">
            {/* Display the phone number, email */}
            <div className="w-full flex flex-col sm:items-start items-center gap-5 ">
              <div className="flex items-center text-xl">
                <FiPhone />
                <p className="ml-5">514-402-3979</p>
              </div>
              <div className="flex items-center text-xl">
                <FiMail />
                <p className="ml-5">volleydynamique@gmail.com</p>
              </div>
            </div>

            {/* Display the three locations with icons */}
            <div className="w-full sm:items-start items-center gap-5 mt-5">
              <div className="space-y-4">
                {locations.map((location, index) => (
                  <div
                    key={index}
                    className="flex flex-col items-center sm:flex-row sm:items-start sm:gap-4"
                  >
                    <div className="text-customRed">
                      <FaMapMarkerAlt size={24} />
                    </div>
                    <div className="text-center sm:text-left">
                      <a
                        href={location.directionsUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="font-medium text-lg hover:text-customBlue hover:font-semibold"
                      >
                        {location.title}
                      </a>
                      <p className="text-gray-600">{location.address}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="w-full flex flex-col my-5 sm:my-10">
            {/* Form to send an email */}
            <form
              className="w-full flex flex-col max-w-lg text-lg mx-auto"
              action="https://formsubmit.co/volleydynamique@gmail.com"
              method="POST"
            >
              <div className="flex flex-col sm:flex-row sm:gap-2">
                <input
                  type="text"
                  name="full_name"
                  placeholder={t("common.contact.content.name")}
                  className="w-full mb-3 rounded-sm"
                />
                <input
                  type="text"
                  name="phone"
                  placeholder={t("common.contact.content.phone")}
                  className="w-full mb-3 rounded-sm"
                />
              </div>
              <input
                type="email"
                name="email"
                placeholder={t("common.contact.content.email")}
                className="mb-3 rounded-sm"
              />
              <textarea
                name="messages"
                cols="30"
                rows="7"
                placeholder="Message"
                className="mb-2 rounded-sm"
              ></textarea>
              <input
                type="hidden"
                name="_next"
                value="https://www.volleyballdynamique.ca/thankyou"
              />
              <input type="hidden" name="_captcha" value="false" />
              <input
                type="hidden"
                name="_subject"
                value="New Message from Contact Us Page"
              />
              <button
                className="w-full h-[45px] bg-customRed rounded-lg text-white font-semibold text-2xl my-5"
                type="submit"
              >
                {t("common.contact.button")}
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Display the map */}
      <div className="w-full">
        <MapContainer
          center={[45.507751, -73.77959]} // Center on the first location
          zoom={10} // Initial zoom level
          style={{ height: "400px", width: "100%" }} // Set width to 100%
          scrollWheelZoom={false} // Disable scroll wheel zoom
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {locations.map((location, index) => (
            <Marker
              key={index}
              position={[location.lat, location.lng]}
              icon={defaultIcon}
            >
              <Popup>
                <a
                  href={location.directionsUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:text-blue-800 underline"
                >
                  {location.title}
                </a>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
    </div>
  );
};

export default ContactPage;