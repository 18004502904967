import React from 'react';
import { useTranslation } from 'react-i18next';

const VolleyballCode = () => {
  const { t } = useTranslation();

  return (
    <div className="p-10 min-h-screen">
      <div className="max-w-4xl mx-auto bg-white p-16 rounded-lg shadow-lg">
        {/* Title */}
        <h1 className="text-4xl font-bold text-center mb-6">{t('codeOfConduct.title')}</h1>

        {/* Introduction */}
        <section className="mb-8">
          <p className="mb-4">
            {t('codeOfConduct.introduction.text1')}{' '}
            <a
              href="https://www.volleyball.qc.ca/wp-content/uploads/2021/02/BUR_PolitiqueIntegrite_VolleyballQuebec.pdf"
              className="text-blue-500 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('codeOfConduct.introduction.linkText')}
            </a>.
          </p>
          <p>{t('codeOfConduct.introduction.text2')}</p>
        </section>

        {/* Coach Code of Conduct */}
        <section className="mb-8">
          <h2 className="text-3xl font-semibold text-customRed mb-4 py-4">{t('codeOfConduct.coach.title')}</h2>
          <div className="space-y-6">
            {/* Professionalism */}
            <div>
              <h3 className="text-xl font-semibold mb-2">{t('codeOfConduct.coach.professionalism.title')}</h3>
              <ul className="list-disc px-12">
                <li>{t('codeOfConduct.coach.professionalism.behavior')}</li>
                <li>{t('codeOfConduct.coach.professionalism.attire')}</li>
              </ul>
            </div>

            {/* Respect and Sportsmanship */}
            <div>
              <h3 className="text-xl font-semibold mb-2">{t('codeOfConduct.coach.respectAndSportsmanship.title')}</h3>
              <ul className="list-disc px-12">
                <li>{t('codeOfConduct.coach.respectAndSportsmanship.players')}</li>
                <li>{t('codeOfConduct.coach.respectAndSportsmanship.opponents')}</li>
                <li>{t('codeOfConduct.coach.respectAndSportsmanship.officials')}</li>
              </ul>
            </div>

            {/* Communication */}
            <div>
              <h3 className="text-xl font-semibold mb-2">{t('codeOfConduct.coach.communication.title')}</h3>
              <ul className="list-disc px-12">
                <li>{t('codeOfConduct.coach.communication.clarity')}</li>
                <li>{t('codeOfConduct.coach.communication.feedback')}</li>
              </ul>
            </div>

            {/* Player Development */}
            <div>
              <h3 className="text-xl font-semibold mb-2">{t('codeOfConduct.coach.playerDevelopment.title')}</h3>
              <ul className="list-disc px-12">
                <li>{t('codeOfConduct.coach.playerDevelopment.skillBuilding')}</li>
                <li>{t('codeOfConduct.coach.playerDevelopment.wellBeing')}</li>
              </ul>
            </div>

            {/* Inclusivity and Equality */}
            <div>
              <h3 className="text-xl font-semibold mb-2">{t('codeOfConduct.coach.inclusionAndEquality.title')}</h3>
              <ul className="list-disc px-12">
                <li>{t('codeOfConduct.coach.inclusionAndEquality.opportunities')}</li>
                <li>{t('codeOfConduct.coach.inclusionAndEquality.respect')}</li>
              </ul>
            </div>

            {/* Safety and Compliance */}
            <div>
              <h3 className="text-xl font-semibold mb-2">{t('codeOfConduct.coach.safetyAndCompliance.title')}</h3>
              <ul className="list-disc px-12">
                <li>{t('codeOfConduct.coach.safetyAndCompliance.protocols')}</li>
                <li>{t('codeOfConduct.coach.safetyAndCompliance.regulations')}</li>
              </ul>
            </div>

            {/* Ethics and Integrity */}
            <div>
              <h3 className="text-xl font-semibold mb-2">{t('codeOfConduct.coach.ethicsAndIntegrity.title')}</h3>
              <ul className="list-disc px-12">
                <li>{t('codeOfConduct.coach.ethicsAndIntegrity.honesty')}</li>
                <li>{t('codeOfConduct.coach.ethicsAndIntegrity.conflictOfInterest')}</li>
              </ul>
            </div>

            {/* Team Management */}
            <div>
              <h3 className="text-xl font-semibold mb-2">{t('codeOfConduct.coach.teamManagement.title')}</h3>
              <ul className="list-disc px-12">
                <li>{t('codeOfConduct.coach.teamManagement.organization')}</li>
                <li>{t('codeOfConduct.coach.teamManagement.decisionMaking')}</li>
              </ul>
            </div>

            {/* Parent and Community Relations */}
            <div>
              <h3 className="text-xl font-semibold mb-2">{t('codeOfConduct.coach.parentAndCommunityRelations.title')}</h3>
              <ul className="list-disc px-12">
                <li>{t('codeOfConduct.coach.parentAndCommunityRelations.engagement')}</li>
                <li>{t('codeOfConduct.coach.parentAndCommunityRelations.support')}</li>
              </ul>
            </div>

            {/* Role Model */}
            <div>
              <h3 className="text-xl font-semibold mb-2">{t('codeOfConduct.coach.roleModel.title')}</h3>
              <ul className="list-disc px-12">
                <li>{t('codeOfConduct.coach.roleModel.behavior')}</li>
                <li>{t('codeOfConduct.coach.roleModel.attitude')}</li>
              </ul>
            </div>
          </div>
        </section>

        {/* Player Code of Conduct */}
        <section className="mb-8">
          <h2 className="py-4 text-3xl text-customRed font-semibold mb-4">{t('codeOfConduct.player.title')}</h2>
          <div className="space-y-6">
            {/* Respect and Sportsmanship */}
            <div>
              <h3 className="text-xl font-semibold mb-2">{t('codeOfConduct.player.respectAndSportsmanship.title')}</h3>
              <ul className="list-disc px-12">
                <li>{t('codeOfConduct.player.respectAndSportsmanship.respect')}</li>
                <li>{t('codeOfConduct.player.respectAndSportsmanship.sportsmanship')}</li>
              </ul>
            </div>

            {/* Commitment and Attendance */}
            <div>
              <h3 className="text-xl font-semibold mb-2">{t('codeOfConduct.player.commitmentAndAttendance.title')}</h3>
              <ul className="list-disc px-12">
                <li>{t('codeOfConduct.player.commitmentAndAttendance.punctuality')}</li>
                <li>{t('codeOfConduct.player.commitmentAndAttendance.participation')}</li>
              </ul>
            </div>

            {/* Teamwork and Cooperation */}
            <div>
              <h3 className="text-xl font-semibold mb-2">{t('codeOfConduct.player.teamworkAndCooperation.title')}</h3>
              <ul className="list-disc px-12">
                <li>{t('codeOfConduct.player.teamworkAndCooperation.collaboration')}</li>
                <li>{t('codeOfConduct.player.teamworkAndCooperation.communication')}</li>
              </ul>
            </div>

            {/* Responsibility and Accountability */}
            <div>
              <h3 className="text-xl font-semibold mb-2">{t('codeOfConduct.player.responsibilityAndAccountability.title')}</h3>
              <ul className="list-disc px-12">
                <li>{t('codeOfConduct.player.responsibilityAndAccountability.accountability')}</li>
                <li>{t('codeOfConduct.player.responsibilityAndAccountability.preparation')}</li>
              </ul>
            </div>

            {/* Respect for Rules and Regulations */}
            <div>
              <h3 className="text-xl font-semibold mb-2">{t('codeOfConduct.player.respectForRulesAndRegulations.title')}</h3>
              <ul className="list-disc px-12">
                <li>{t('codeOfConduct.player.respectForRulesAndRegulations.adherence')}</li>
                <li>{t('codeOfConduct.player.respectForRulesAndRegulations.safety')}</li>
              </ul>
            </div>

            {/* Behavior and Conduct */}
            <div>
              <h3 className="text-xl font-semibold mb-2">{t('codeOfConduct.player.behaviorAndConduct.title')}</h3>
              <ul className="list-disc px-12">
                <li>{t('codeOfConduct.player.behaviorAndConduct.behavior')}</li>
                <li>{t('codeOfConduct.player.behaviorAndConduct.integrity')}</li>
              </ul>
            </div>

            {/* Respect for Equipment and Facilities */}
            <div>
              <h3 className="text-xl font-semibold mb-2">{t('codeOfConduct.player.respectForEquipmentAndFacilities.title')}</h3>
              <ul className="list-disc px-12">
                <li>{t('codeOfConduct.player.respectForEquipmentAndFacilities.care')}</li>
                <li>{t('codeOfConduct.player.respectForEquipmentAndFacilities.cleanliness')}</li>
              </ul>
            </div>

            {/* Focus and Attitude */}
            <div>
              <h3 className="text-xl font-semibold mb-2">{t('codeOfConduct.player.focusAndAttitude.title')}</h3>
              <ul className="list-disc px-12">
                <li>{t('codeOfConduct.player.focusAndAttitude.attitude')}</li>
                <li>{t('codeOfConduct.player.focusAndAttitude.focus')}</li>
              </ul>
            </div>

            {/* Personal Development */}
            <div>
              <h3 className="text-xl font-semibold mb-2">{t('codeOfConduct.player.personalDevelopment.title')}</h3>
              <ul className="list-disc px-12">
                <li>{t('codeOfConduct.player.personalDevelopment.growth')}</li>
                <li>{t('codeOfConduct.player.personalDevelopment.wellBeing')}</li>
              </ul>
            </div>

            {/* Parent and Community Engagement */}
            <div>
              <h3 className="text-xl font-semibold mb-2">{t('codeOfConduct.player.parentAndCommunityEngagement.title')}</h3>
              <ul className="list-disc px-12">
                <li>{t('codeOfConduct.player.parentAndCommunityEngagement.representation')}</li>
              </ul>
            </div>
          </div>
        </section>

        {/* Parent Code of Conduct */}
        <section className="mb-8">
          <h2 className="py-4 text-3xl text-customRed font-semibold mb-4">{t('codeOfConduct.parent.title')}</h2>
          <div className="space-y-6">
            {/* Support and Encouragement */}
            <div>
              <h3 className="text-xl font-semibold mb-2">{t('codeOfConduct.parent.supportAndEncouragement.title')}</h3>
              <ul className="list-disc px-12">
                <li>{t('codeOfConduct.parent.supportAndEncouragement.positiveSupport')}</li>
                <li>{t('codeOfConduct.parent.supportAndEncouragement.respect')}</li>
              </ul>
            </div>

            {/* Communication */}
            <div>
              <h3 className="text-xl font-semibold mb-2">{t('codeOfConduct.parent.communication.title')}</h3>
              <ul className="list-disc px-12">
                <li>{t('codeOfConduct.parent.communication.communicationWithCoaches')}</li>
              </ul>
            </div>

            {/* Respect for Coaches and Officials */}
            <div>
              <h3 className="text-xl font-semibold mb-2">{t('codeOfConduct.parent.respectForCoachesAndOfficials.title')}</h3>
              <ul className="list-disc px-12">
                <li>{t('codeOfConduct.parent.respectForCoachesAndOfficials.respect')}</li>
                <li>{t('codeOfConduct.parent.respectForCoachesAndOfficials.support')}</li>
              </ul>
            </div>

            {/* Respect for Club Policies */}
            <div>
              <h3 className="text-xl font-semibold mb-2">{t('codeOfConduct.parent.respectForClubPolicies.title')}</h3>
              <ul className="list-disc px-12">
                <li>{t('codeOfConduct.parent.respectForClubPolicies.compliance')}</li>
                <li>{t('codeOfConduct.parent.respectForClubPolicies.support')}</li>
              </ul>
            </div>

            {/* Conflict Resolution */}
            <div>
              <h3 className="text-xl font-semibold mb-2">{t('codeOfConduct.parent.conflictResolution.title')}</h3>
              <ul className="list-disc px-12">
                <li>{t('codeOfConduct.parent.conflictResolution.approach')}</li>
                <li>{t('codeOfConduct.parent.conflictResolution.collaboration')}</li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default VolleyballCode;