import React from 'react';
import { useTranslation } from 'react-i18next';

const ClubPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="p-10 min-h-screen">
      <div className="max-w-4xl mx-auto bg-white p-16 rounded-lg shadow-lg">
        {/* Title */}
        <h1 className="text-4xl font-bold text-center mb-12">{t('clubPolicies.title')}</h1>

        {/* Registration */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">{t('clubPolicies.registration.title')}</h2>
          <p className="mb-4">{t('clubPolicies.registration.description')}</p>
        </section>

        {/* Safety and Health */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">{t('clubPolicies.safetyAndHealth.title')}</h2>
          <ul className="list-disc pl-6">
            <li>{t('clubPolicies.safetyAndHealth.equipment')}</li>
            <li>{t('clubPolicies.safetyAndHealth.injuries')}</li>
            <li>{t('clubPolicies.safetyAndHealth.health')}</li>
          </ul>
        </section>

        {/* Harassment and Discrimination */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">{t('clubPolicies.harassmentAndDiscrimination.title')}</h2>
          <ul className="list-disc pl-6">
            <li>{t('clubPolicies.harassmentAndDiscrimination.zeroTolerance')}</li>
            <li>{t('clubPolicies.harassmentAndDiscrimination.reporting')}</li>
          </ul>
        </section>

        {/* Travel */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">{t('clubPolicies.travel.title')}</h2>
          <ul className="list-disc pl-6">
            <li>{t('clubPolicies.travel.transportation')}</li>
            <li>{t('clubPolicies.travel.accommodations')}</li>
          </ul>
        </section>

        {/* Social Media */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">{t('clubPolicies.socialMedia.title')}</h2>
          <ul className="list-disc pl-6">
            <li>{t('clubPolicies.socialMedia.posting')}</li>
            <li>{t('clubPolicies.socialMedia.privacy')}</li>
          </ul>
        </section>

        {/* Financial */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">{t('clubPolicies.financial.title')}</h2>
          <ul className="list-disc pl-6">
            <li>{t('clubPolicies.financial.fees')}</li>
            <li>{t('clubPolicies.financial.fundraising')}</li>
            <li>{t('clubPolicies.financial.refunds')}</li>
            <ul className="list-none px-10 pt-3">
              <li>{t('clubPolicies.financial.refundPolicy1')}</li>
              <li>{t('clubPolicies.financial.refundPolicy2')}</li>
              <li>{t('clubPolicies.financial.refundPolicy3')}</li>
            </ul>
          </ul>
        </section>

        {/* Discipline */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">{t('clubPolicies.discipline.title')}</h2>
          <ul className="list-disc pl-6">
            <li>{t('clubPolicies.discipline.expectations')}</li>
            <li>{t('clubPolicies.discipline.procedures')}</li>
          </ul>
        </section>

        {/* Volleyball Risks */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">{t('clubPolicies.volleyballRisks.title')}</h2>
          <p>{t('clubPolicies.volleyballRisks.description')}</p>
        </section>

        {/* Responsibility */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">{t('clubPolicies.responsibility.title')}</h2>
          <p>{t('clubPolicies.responsibility.description')}</p>
        </section>

        {/* Personal Equipment */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">{t('clubPolicies.personalEquipment.title')}</h2>
          <p>{t('clubPolicies.personalEquipment.description')}</p>
          <ul className="list-disc pl-6">
            {t('clubPolicies.personalEquipment.items', { returnObjects: true }).map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </section>
      </div>
    </div>
  );
};

export default ClubPolicy;