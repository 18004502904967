import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Location = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-start">
      <div className="font-semibold text-darkBlue text-xl text-center">
        {t('common.newsletter.location')}
      </div>
      <div className="text-white text-lg flex flex-col">
        <Link to="/contact" className="hover:text-customYellow">West Island</Link>
        <Link to="/contact" className="hover:text-customYellow">Montreal</Link>
        <Link to="/contact" className="hover:text-customYellow">Southshore</Link>
      
      </div>
    </div>
  );
};

export default Location;
