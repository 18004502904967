import React from "react";
import ClubPolicy from "../components/ClubPolicy";

const PolicyPage = () => {
  return (
      <div className="w-full flex flex-col items-center">

          <ClubPolicy />
        
      </div>
    );
};

export default PolicyPage;
